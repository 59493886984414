//React-RTK
import { BrowserRouter } from 'react-router-dom';
import AuthLogin from './features/Auth/AuthLogin';

// MUI
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

//Theme
import anheuserBusch22 from './themes/abTheme/anheuserBusch22';

// Routes
import AllRoutes from './routes/AllRoutes';
import { priceApi } from './api/priceApi/priceApi';
import { useEmptyApiErrorEnhancements } from './api/priceApi/emptyApi';

const theme = anheuserBusch22;

//Root component for the PRC Application
const App = () => {
  useEmptyApiErrorEnhancements(priceApi);

  return (
    /*
        StylesProvider is used to set the CSS injection order for mui https://mui.com/material-ui/guides/interoperability/#css-injection-order
        This is needed to allow other CSS and styling systems to override MUI 
  */ <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthLogin>
            <BrowserRouter>
              <AllRoutes />
            </BrowserRouter>
          </AuthLogin>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
